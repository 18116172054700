<template>
  <v-card>
    <v-card-title></v-card-title>
    <v-card-text>
      <div class="user-avatar">
        <v-avatar>
          <img :src="requestUser.avatar" alt="John" v-if="requestUser.avatar" />
          <span v-else class="text-h5">{{
            requestUser.name.substr(0, 2)
          }}</span>
        </v-avatar>

        <p class="ml-5">{{ requestUser.name }}</p>
      </div>

      <h2>Contact Details</h2>

      <h4>Email</h4>
      <p class="email">{{ requestUser.email }}</p>

      <h4>Phone</h4>
      <p class="email">{{ requestUser.contact }}</p>

      <div v-if="userMetaDetails && userMetaDetails.length">
        <h4 class="mb-2">User Details</h4>
        <ul class="user-details">
          <li
            class="user-details--item"
            v-for="details in userMetaDetails"
            :key="details.label"
          >
            <p class="label">{{ details.label }}</p>
            <p class="value">{{ details.value }}</p>
          </li>
        </ul>
      </div>

      <h2>User Location</h2>
      <p class="address">
        {{ formatedAddess }}
      </p>

      <template v-if="meta">
        <h2>Additional Info</h2>
        <h4>He/She would like to own.</h4>

        <div class="own-type" v-for="t in ownType" :key="t.icon">
          <v-icon>{{ t.icon }}</v-icon>
          <p>{{ t.label }}</p>
        </div>

        <h4>Selected Days</h4>
        <div class="days">
          <div v-for="day in daysDetails" :key="day.state" class="days-item">
            <span class="state" :class="`state-${day.state}`"></span>
            <span class="text">{{ day.text }}</span>
          </div>
        </div>

        <WeekDays :days="meta.days" />
      </template>

      <Button block color="light-grey" class="mt-5" @click="$emit('cancel')"
        >Cancel</Button
      >
    </v-card-text>
  </v-card>
</template>

<script>
import WeekDays from "@/components/week-days/week-days";
import { GARDEN_SIZES } from "@/constants";
export default {
  name: "request-user-details",
  components: { WeekDays },
  props: {
    request: { type: Object, required: true },
  },
  computed: {
    requestUser() {
      return this.request.user;
    },
    formatedAddess() {
      const { address, city, country } = this.requestUser;
      return `${address}, ${city}, ${country}`;
    },
    meta() {
      return this.request.meta_data;
    },
    ownOptions() {
      return [
        {
          label: "Go for a walk",
          value: "walk",
          icon: "mdi-dog-service",
          isIcon: true,
        },
        {
          label: "In house ",
          value: "house",
          icon: "mdi-home",
          isIcon: true,
        },
        {
          label: "Travel with pet",
          value: "travel",
          icon: "mdi-compass-outline",
          isIcon: true,
        },
      ];
    },

    ownType() {
      if (!this.meta) return false;
      const { ownType } = this.meta;
      return this.ownOptions.filter((type) => ownType.includes(type.value));
    },

    daysDetails() {
      return [
        {
          state: 1,
          text: "Full day",
        },
        {
          state: 2,
          text: "For Am",
        },
        {
          state: 3,
          text: "For Pm",
        },
      ];
    },

    userMetaDetails() {
      const { meta_data } = this.request.user;
      if (!meta_data || meta_data == "null") return false;
      let meta = meta_data;
      if (typeof meta_data === "string") meta = JSON.parse(meta_data);
      const {
        noOfKids,
        garden_size,
        otherAnimals,
        kidsAges,
        otherAnimalsTypes,
      } = meta;
      let gardenValue = GARDEN_SIZES[0].text;

      const index = GARDEN_SIZES.findIndex((g) => g.value === garden_size);
      if (index !== -1) gardenValue = GARDEN_SIZES[index].text;

      return [
        {
          label: "No. of Kids",
          value: noOfKids,
        },
        {
          label: "Kids Ages",
          value: noOfKids > 0 ? kidsAges.join(", ") : "--",
        },
        {
          label: "garden",
          value: gardenValue,
        },
        {
          label: "Animals in house",
          value: otherAnimals,
        },
        {
          label: "Animal type",
          value: otherAnimals > 0 ? otherAnimalsTypes.join(", ") : "--",
        },
      ];
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
p {
  margin-bottom: 10px;
}
.user-avatar {
  display: flex;
  align-items: center;
  p {
    font-size: 18px;
    font-weight: 500;
    margin: 0;
  }
}

h2 {
  margin: 1rem 0 0.5rem 0;
}

.days {
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-bottom: 1px solid #ddd;
  padding: 5px;
  margin-bottom: 10px;

  &-item {
    display: flex;
    align-items: center;
    margin: 5px 0;

    span {
      display: inline-block;

      &.text {
        font-size: 10px;
        line-height: 12px;
        color: #868686;
        margin-left: 10px;
      }

      &.state {
        height: 12px;
        width: 12px;
        background-color: #e5e7eb;
        border-radius: 50%;
        overflow: hidden;
        position: relative;

        &-1 {
          background-color: #0dbff2;
        }

        &-2 {
          &:before {
            content: "";
            top: 0;
            height: 50%;
            width: 100%;
            left: 0;
            background-color: #f6c15a;
            position: absolute;
          }
        }

        &-3 {
          &:after {
            content: "";
            width: 100%;
            top: 50%;
            left: 0;
            bottom: 0;
            background-color: #0dbff2;
            position: absolute;
          }
        }
      }
    }
  }
}

.own-type {
  display: flex;
  align-items: center;
  padding: 0.8rem;
  border: 1px solid rgba(51, 51, 51, 0.4);
  border-radius: 12px;
  margin: 0.5rem 0 0.8rem 0;

  p {
    margin-left: 1rem;
    margin-bottom: 0;
  }
}

.user-details {
  padding: 0;
  margin: 0;
  list-style: none;

  &--item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p.value {
      font-weight: bold;
    }
  }
}
</style>
