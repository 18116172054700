<template>
  <div class="page-container my-3">
    <loader :loading="loading.petDetail" overlay>
      <div class="pet-details" v-if="pet">
        <v-breadcrumbs :items="breadcrumbs" large class="pl-0"></v-breadcrumbs>
        
        <div class="general-info">
          <div class="pet" v-if="pet.media">
            <v-img
              :src="pet.media[0]"
              height="70px"
              max-width="70px"
              class="pet-image"
              cover
              center
            ></v-img>
            <div class="ml-5">
              <h3 class="pet-name">{{ pet.name }}</h3>
              <p class="pet-category">
                {{ pet.category && pet.category.name }},
                {{ pet.race && pet.race.name }}
              </p>
            </div>
          </div>
          <div>
            <Button color="light-grey" class="px-10 mr-3" @click="editPet"
              >Edit</Button
            >

            <ShareNetwork
              network="facebook"
              url="https://play.google.com/store/apps/details?id=com.mpawer.co"
              title="Pet image"
            >
              <Button class="px-10"> Share</Button>
            </ShareNetwork>
          </div>
        </div>

        <v-tabs v-model="tab" background-color="transparent" class="mt-10">
          <v-tab v-for="item in items" :key="item">
            {{ item }}
            <span class="red-dot" v-if="hasRequests(item)"></span>
            
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab" background-color="transparent">
          <v-tab-item>
            <v-row justify="space-between">
              <v-col cols="12" md="5">
                <h3>General Info</h3>

                <div class="list">
                  <div
                    class="list-item"
                    v-for="info in generalInfoList"
                    :key="info.label"
                  >
                    <p class="label">{{ info.label }}</p>
                    <p class="value">{{ info.value }}</p>
                  </div>
                </div>

                <h3 class="mt-10">Additional Notes</h3>
                <p class="description">
                  {{ pet.description }}
                </p>
              </v-col>
              <v-col cols="12" md="5">
                <h3>Animal Behaviour</h3>
                <div class="list">
                  <div
                    class="list-item"
                    v-for="info in animalBehaviorList"
                    :key="info.label"
                  >
                    <p class="label">{{ info.label }}</p>
                    <p class="value" :class="!info.value && 'error--text'">
                      {{ info.value ? "Yes" : "No" }}
                    </p>
                  </div>
                </div>

                <h3 class="mt-5">Media Gallery</h3>
                <div class="media-gallery">
                  <v-img
                    v-for="media in pet.media"
                    :key="media"
                    :src="media"
                    class="media-gallery-image"
                    height="80px"
                    max-width="80px"
                    cover
                    center
                  >
                    <template v-slot:placeholder>
                      <v-progress-circular
                        indeterminate
                        color="primary"
                      ></v-progress-circular>
                    </template>
                  </v-img>
                </div>

                <Button
                  large
                  outlined
                  color="error"
                  class="mt-10"
                  @click="deleteListing"
                  :loading="loading.petDelete"
                  >Delete this listing</Button
                >
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item>
            <v-card outlined elevation="0" style="border-radius: 12px">
              <v-card-text>
                <h2 class="mb-5">All Requests ({{ requests.length }})</h2>
                <v-row
                  v-for="req in requests"
                  :key="req.id"
                  :class="req.read ==0 ?'request-list new-req-bg':'request-list'"
                  align="center"
                >
                
                  <v-col cols="3" @click="openDetails(req)">
                  
                    <div class="user-avatar">
                      <v-avatar>
                        <img
                          :src="req.user.avatar"
                          alt="John"
                          v-if="req.user.avatar"
                        />
                        <span v-else class="text-h5">{{
                          req.user.name.substr(0, 2)
                        }}</span>
                      </v-avatar>

                      <p class="ml-3">{{ req.user.name }}</p>
                    </div>
                  </v-col>
                  <v-col cols="2">
                    <p>
                      {{ req.user.email }}
                    </p>
                  </v-col>
                  <v-col cols="2">
                    <p>
                      {{ req.user.contact ? req.user.contact : "--" }}
                    </p>
                  </v-col>
                  <v-col cols="2">
                    <div
                      class="icons-container"
                      v-if="getMetaDetails(req) && getMetaDetails(req).length"
                    >
                      <div
                        v-for="detail in getMetaDetails(req)"
                        :key="detail.icon"
                        class="mr-2"
                      >
                        <img
                          :src="getIcon(detail.icon)"
                          height="20px"
                          width="20px"
                        />
                        <div class="primary--text">{{ detail.value }}</div>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="3">
                    <template
                      v-if="ADOPTION_REQUEST_STATUS.REVIEW === req.status"
                    >
                      <v-btn
                        icon
                        color="error"
                        class="mr-3"
                        :loading="loading.rejectRequest"
                        :disabled="loading.rejectRequest"
                        @click="rejectRequest(req)"
                      >
                        <v-icon>mdi-thumb-down</v-icon>
                      </v-btn>

                      <v-btn
                        icon
                        color="success"
                        @click="approveRequest(req)"
                        :loading="loading.acceptRequest"
                        :disabled="loading.acceptRequest"
                      >
                        <v-icon>mdi-thumb-up</v-icon>
                      </v-btn>
                    </template>
                    <p class="status" v-else :class="req.status">
                      {{ req.status }}
                    </p>

                    <v-btn
                      icon
                      color="primary"
                      class="ml-3"
                      @click="openDetails(req)"
                    >
                      <v-icon>mdi-eye</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </loader>

    <CreateEditPet
      v-if="pet"
      v-model="openDialog"
      :key="openDialog"
      :editMode="true"
      @success="FETCH_SINGLE_PET($route.params.id)"
    />

    <v-dialog v-model="openUserDetails" max-width="400px">
      <RequestUserDetails
        v-if="activeRequest"
        :request="activeRequest"
        @cancel="openUserDetails = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { ShareNetwork } from 'vue-social-sharing';
const petModule = createNamespacedHelpers("pet");

import { CreateEditPet } from "@/components";
import RequestUserDetails from "@/components/pet/request-user-details";
import { ADOPTION_REQUEST_STATUS, GARDEN_SIZES } from "@/constants";
import { calculateAge } from "@/helpers/functions";

export default {
  name: "pet-detail",
  components: { CreateEditPet, RequestUserDetails, ShareNetwork },
  data() {
    return {
      openDialog: false,
      openUserDetails: false,
      activeRequest: null,
      tab: 0,
      items: ["about", "requests"],
      ADOPTION_REQUEST_STATUS,
      windowFeatures: {},
      useNativeBehavior: false,
      currentUrl: document.URL
    };
  },
  computed: {
    ...petModule.mapState(["loading", "pet", "requests"]),

    breadcrumbs() {
      return [
        {
          text: "My Pets",
          disabled: false,
          to: "/"
        },
        {
          text: this.pet ? this.pet.name : "Pets",
          disabled: true
        }
      ];
    },
    generalInfoList() {
      if (!this.pet) return [];
      const { category, race, date_of_birth, gender, meta_data } = this.pet;
      if (!meta_data) return [];
      const {
        garden_length,
        garden_width,
        minimal_adopt,
        active_hours,
        garden,
        garden_size
      } = meta_data;

      const arr = [
        {
          label: "Animal Type",
          value: category && category.name
        },
        {
          label: "Age",
          value: calculateAge(date_of_birth)
        },
        {
          label: "Race",
          value: race && race.name
        },
        {
          label: "Gender",
          value: gender
        },
        {
          label: "Minimal Adoption Amount",
          value: minimal_adopt
        },
        {
          label: "Garden",
          value:
            garden_size && GARDEN_SIZES.find(g => g.value === garden_size).text
        },
        {
          label: "Garden Size ",
          value: garden && `${garden_length} x ${garden_width}`
        },
        {
          label: "Active Recommended Time",
          value: active_hours
        }
      ];
      return arr.filter(a => a.value);
    },
    animalBehaviorList() {
      if (!this.pet) return [];
      const {
        meta_data: { pet_interaction }
      } = this.pet;
      return [
        {
          label: "Kids Friendly",
          value: pet_interaction.includes("kids_friendly")
        },
        {
          label: "Dog Friendly",
          value: pet_interaction.includes("dog_friendly")
        },
        {
          label: "Other Animal Friendly",
          value: pet_interaction.includes("other")
        }
      ];
    }
  },
  methods: {
    ...petModule.mapActions([
      "FETCH_SINGLE_PET",
      "DELETE_PET",
      "ACCEPT_REQUEST",
      "REJECT_REQUEST",
      "MARK_REQUEST_READ"
    ]),
    ...petModule.mapMutations(["RESET_CREATE_PET_OBJ"]),
    deleteListing() {
      this.DELETE_PET(this.$route.params.id);
    },
    editPet() {
      this.openDialog = true;
    },
    rejectRequest(req) {
      this.REJECT_REQUEST(req.id);
    },
    approveRequest(req) {
      this.ACCEPT_REQUEST(req.id);
    },
    openDetails(req) {
    if(req.read == 0){

      this.MARK_REQUEST_READ(req.id)
    }
    
      this.activeRequest = req;
      this.openUserDetails = true;
    },
    getMetaDetails(req) {
      const { meta_data } = req.user;
      if (!meta_data || meta_data == "null") return false;
      let meta = meta_data;
      if (typeof meta_data === "string") meta = JSON.parse(meta_data);
      const { noOfKids, garden_size, otherAnimals } = meta;
      let gardenValue = "no";

      if (garden_size === "small") gardenValue = "<= 200";
      if (garden_size === "big") gardenValue = "=> 200";

      return [
        {
          icon: "kids",
          value: noOfKids
        },
        {
          icon: "garden",
          value: gardenValue
        },
        {
          icon: "animals",
          value: otherAnimals
        }
      ];
    },
    getIcon(svg) {
      return require(`@/assets/svg/${svg}.svg`);
    },
    hasRequests(type) {

      const req = this.requests.find(({read})=> read == 0)
      if (type === "about") return false;
      else if (type === "requests" && req == undefined){
        return false;
      }
      else if((type === "requests" && req != undefined)){
        return true;
      }
    }
  },
  created() {
this.FETCH_SINGLE_PET(this.$route.params.id);
  },
  beforeDestroy() {
    this.RESET_CREATE_PET_OBJ();
  }
};
</script>

<style lang="scss" scoped>
p {
  word-break: break-all;
}
.page-container {
  max-width: 1000px;
  margin: auto;

  .pet-details {
    .general-info {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .pet {
        display: flex;
        align-items: center;

        .pet-image {
          border-radius: 12px;
        }

        .pet-name {
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 16px;
          color: #03354d;
          margin-bottom: 10px;
        }

        .pet-category {
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
          color: #7f7d94;
          margin: 0;
        }
      }
    }
  }

  .theme--light.v-tabs-items {
    background-color: transparent;
  }

  .v-window-item.v-window-item--active {
    padding-top: 1.5rem;
  }

  .v-tab {
    font-family: sans-serif;
    text-transform: capitalize;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
  }

  &::v-deep .v-slide-group__content {
    border-bottom: 1px solid rgba(3, 53, 77, 0.3);
  }
}

.list {
  &-item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #7f7d94;
    }

    p.value {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #03354d;
    }
  }
}

h3 {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #03354d;
  margin-bottom: 1rem;
}

p.description {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #7f7d94;
  word-break: break-word;
}

.media-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, 80px);
  grid-gap: 1rem;

  &-image {
    border-radius: 12px;
  }
}

h2 {
  font-weight: 500;
  font-size: 18px;
  line-height: 16px;
  color: #03354d;
}
.request-list {
  .user-avatar {
    display: flex;
    align-items: center;

    p {
      font-weight: 600;
    }
  }

  p {
    margin-bottom: 0;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
  }
}

p.status {
  text-transform: capitalize;
  font-weight: bold;

  &.approved {
    color: #5defcc;
  }

  &.rejected {
    color: #ff5f5f;
  }
}

.icons-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  > div {
    text-align: center;
  }
}

.red-dot {
  display: block;
  height: 6px;
  width: 6px;
  background: #ff5f5f;
  border-radius: 100%;
  position: absolute;
  right: 0;
}
.new-req-bg{
  background: aliceblue;
}
</style>
